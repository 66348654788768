@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
*{
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 768px) {
  *{
    font-size: 12px;
    font-weight: 500;
  }
}